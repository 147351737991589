import React, { useState, useEffect } from "react";
import Banner from "../Components/BannerTop";
import Cardview from "../Components/Cardview";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
function Home() {
  return (
    <main>
      <Banner Title="Why Tesla" NoSearch={true} />

      {/* <section id="banner_parallax" className="slide_banner1">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="full">
                <div className="slide_cont">
                  <h2>Familiarize Your Creative Application</h2>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium..!
                  </p>
                  <div className="full slide_bt">
                    {" "}
                    <a className="white_bt bt_main" href="index.html">
                      Download
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="full">
                <div
                  className="slide_pc_img wow fadeInRight"
                  data-wow-delay="1s"
                  data-wow-duration="2s"
                >
                  {" "}
                  <img src="images/t2.png" alt="#" className="img-fluid"/>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="layout_padding pt-1">
        <div className="container">
          <div className="row margin_bottom_30 ">
            <div className="col-md-6 cont_theme_blog align-self-center">
              <div className="full">
                <h2>Unique Design</h2>
                <p>
                  The innovative design of the Tesla has captured the attention
                  of the world. Leading the market for electric cars, Tesla
                  provides an automotive experience unlike any other car on the
                  road.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 wow fadeInRight"
              data-wow-delay="0.5"
              data-wow-duration="1s"
            >
              <div className="full">
                <div className="center">
                  <img src="images/t2.png" alt="#" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="row layout_padding_3">
            <div
              className="col-md-6 wow fadeInLeft"
              data-wow-delay="0.5"
              data-wow-duration="1s"
            >
              <div className="full">
                <div className="center">
                  <img
                    src="images/interior.jpg"
                    alt="#"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 cont_theme_blog align-self-center">
              <div className="full">
                <h2>Futuristic Design</h2>
                <p>
                  The Tesla’s beautiful minimalist interior provides a clean
                  looking dashboard. Almost all of the car’s functions run
                  through the large touch-screen display. Here the driver can
                  control the app-based control system that serves as “the
                  brain” of the car.
                </p>
              </div>
            </div>
          </div>
          <div className="row  layout_padding_3">
            <div className="col-md-6 cont_theme_blog align-self-center">
              <div className="full">
                <h2>Autopilot</h2>
                <p>
                  The most notable feature of the car is its autopilot system.
                  While the driver must remain focused on the road with their
                  hands on the wheel, the car will do the rest of the work. When
                  activated, the car’s speed, steering, and brakes will all be
                  controlled by the Tesla as it senses its surroundings and gets
                  the car safely to its destination.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 wow fadeInRight"
              data-wow-delay="0.5"
              data-wow-duration="1s"
            >
              <div className="full">
                <div className="center">
                  <img
                    src="images/autopilot.jpg"
                    alt="#"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout_padding gradiant_bg cross_layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="information_blogs">
                <div className="imf_icon">
                  <i
                    className="fas fa-car fa-4x"
                    style={{ color: "#ff4431" }}
                  ></i>
                </div>
                <div className="imf_head">
                  <h2 className="contact-title">Awesome Features</h2>
                </div>
                <div className="imf_cont">
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusa ntium
                  </p>
                </div>
                <div className="imf_read">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="information_blogs">
                <div className="imf_icon">
                  <i
                    className="fas fa-car fa-4x"
                    style={{ color: "#ff4431" }}
                  ></i>
                </div>
                <div className="imf_head">
                  <h2 className="contact-title">Creative Design</h2>
                </div>
                <div className="imf_cont">
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusa ntium
                  </p>
                </div>
                <div className="imf_read">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="information_blogs">
                <div className="imf_icon">
                  <i
                    className="fas fa-car fa-4x"
                    style={{ color: "#ff4431" }}
                  ></i>
                </div>
                <div className="imf_head">
                  <h2 className="contact-title">24 Hours Supports</h2>
                </div>
                <div className="imf_cont">
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusa ntium
                  </p>
                </div>
                <div className="imf_read">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
