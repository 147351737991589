import React, { Component } from "react";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { withGlobalContext } from "./GlobalContext";

class Spinner extends React.Component {
  //other logic
  render() {
    return (
      <div
        id="loader"
        style={{
          position: "fixed",
          display: this.props.global.isLoading ? "block" : "none",
          height: "100%",
          background: "#00000085",
          zIndex: 99999,

          width: "100%",
        }}
      >
        <Loader
          className="text-center"
          type="TailSpin"
          color="#ff4431"
          height="8%"
          style={{ marginTop: "20%" }}
          width="8%"
          // timeout={10000} //3 secs
        />
      </div>
    );
  }
}

export default withGlobalContext(Spinner);
