import React, { useEffect, useState, useRef } from "react";
import Banner from "../Components/BannerTop";
import { useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axiosWithAuth from "../utils/Services";

import { withGlobalContext } from "../utils/GlobalContext";

const getHttpsURl = (url) => {
  if (!url.includes("https")) {
    return url.replace("http", "https");
  }
  return url;
};
function MyListing(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    props.global.setLoader(true);

    if (props.global.user) {
      axiosWithAuth()
        .post("/latest/api/vehicle/listing", {
          userEmail: props.global.user && props.global.user.attributes.email,
        })
        .then((res) => {
          console.log(res);
          setData(res.data.items);
          props.global.setLoader(false);
        })
        .catch((res) => {
          props.global.setLoader(false);
        });
    }
  }, [props.global.user]);

  return (
    <>
      <Banner NoSearch={true} Title="My Recent Listings" />
      <div>
        <div className="popular-directorya-area  border-bottom section-padding40 fix">
          <div className="container mt-10">
            <div className="row d-flex justify-content-center ">
              <div className="col-10 justify-center ">
                <h2 class="contact-title">All your Tesla listings.</h2>
                {data &&
                  data.map((it) => {
                    return (
                      <div class="row  mb-4 My_Car_Listing" key={it.Vin}>
                        <div class="col-md-6">
                          <img
                            src={
                              it.Photo_Url_List && it.Photo_Url_List.length > 0
                                ? getHttpsURl(it.Photo_Url_List.split(",")[0])
                                : "images/default.png"
                            }
                            class="Car_image img-fluid"
                          />
                        </div>
                        <div class="col-md-6">
                          <div class="My_Car_details">
                            <div class="row mb-4">
                              <div class="col-md-9 col-sm-12 pt-4">
                                <b
                                  class="Car_detail"
                                  style={{ color: "#ff4431", fontSize: "20px" }}
                                >
                                  {`${it.Make} ${it.Model} ${it.ModelNumber} `}{" "}
                                </b>
                              </div>
                              <div class="col-md-3 col-sm-12 pt-4">
                                <b
                                  class="Car_detail"
                                  style={{ fontSize: "20px" }}
                                >
                                  ${it.Price}{" "}
                                </b>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-9 col-sm-6">
                                <p class="Car_detail">Year</p>
                              </div>
                              <div class="col-md-3 col-sm-6">
                                <p>{it.Year}</p>
                              </div>
                              <div class="col-md-9 col-sm-6">
                                <p class="Car_detail">VIN</p>
                              </div>
                              <div class="col-md-3 col-sm-6">
                                <p>{it.Vin}</p>
                              </div>
                              <div class="col-md-9 col-sm-6">
                                <p class="Car_detail">Zip Code</p>
                              </div>
                              <div class="col-md-3 col-sm-6">
                                <p>{it.Dealer_Zip_Code}</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 mt-3">
                                <button
                                  onClick={() =>
                                    props.history.push("sellerform", {
                                      dat: it,
                                    })
                                  }
                                  type="button"
                                  class="btn float-right"
                                >
                                  Edit details
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {!data && <p class="contact-title">No Listing found.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withGlobalContext(MyListing);
