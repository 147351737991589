import axios from "axios";

import globalConfig from "./globalConfig";
// const apiUrl = "https://yojpyt0gri.execute-api.us-east-1.amazonaws.com";//test
const apiUrl = "https://qrqt4vl0ka.execute-api.us-east-1.amazonaws.com"; //prod

const { isTesting } = globalConfig;

const axiosWithAuth = (extra = null) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios.create({
    headers: {
      "Content-Type": "application/json",
      // "X-AuthToken": ` ${user ? user.token : ""}`,
    },
    baseURL: apiUrl,
  });
};
export default axiosWithAuth;
