import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="wantToWork-area">
        <div className="container">
          <div className="wants-wrapper w-padding2">
            <div className="row justify-content-between">
              <div className="col-xl-8 col-lg-8 col-md-7">
                <div className="wantToWork-caption wantToWork-caption2">
                  <img
                    src="assets/img/logo/logo2_footer.png"
                    alt=""
                    className="mb-20"
                  />
                  <p className="tagline">
                    <b>Buy, Sell and Search In One Place.</b> <br />
                    Your Tesla will be listed on our Tesla-exclusive marketplace
                    and will be seen by thousands of buyers looking for exactly
                    what you have.
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-5">
                <div className="footer-social f-right sm-left">
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wantToWork-area">
        <div className="container">
          <div className="wants-wrapper">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-7 col-lg-9 col-md-8">
                <div className="wantToWork-caption wantToWork-caption2">
                  <div className="main-menu2">
                    <nav>
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/listing">Explore</a>
                        </li>

                        <li>
                          <a href="#">Blog</a>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                <Link to="/sellcar" className="btn f-right sm-left">
                  Sell Your Car
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer-wrapper pt-30">
          <div className="footer-bottom-area">
            <div className="container">
              <div className="footer-border">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-xl-10 col-lg-9 ">
                    <div className="footer-copy-right">
                      <p>
                        Copyright &copy;
                        {new Date().getFullYear()}{" "}
                        <b>
                          <span style={{ color: "red" }}>TeslaNavigator </span>
                        </b>
                        | All rights reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
