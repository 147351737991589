import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Amplify, { Auth, Hub, Logger } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import awsConfig from "../awsConfig";
import AuthForm from "../AuthForm";

import "../Awsstyle.css";
Amplify.configure(awsConfig);

function ManagementOptions(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Modal
      className="LoginPopup"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // backdrop="static"
      //show={true}
    >
      {/* <Modal.Header closeButton /> */}
      <AuthForm onHide={props.onHide} show={props.show} />
    </Modal>
  );
}

export default ManagementOptions;
