import React, { useState, useEffect } from "react";
import Banner from "../Components/BannerTop";
import Cardview from "../Components/Cardview";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axiosWithAuth from "../utils/Services";
import { withGlobalContext } from "../utils/GlobalContext";

import { propStyle } from "aws-amplify-react";
function Home(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    new window.TradingView.widget({
      width: 550,
      height: 280,
      symbol: "NASDAQ:TSLA",
      interval: "240",
      timezone: "Etc/UTC",
      theme: "light",
      style: "2",
      locale: "en",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      hide_top_toolbar: true,
      container_id: "tradingview_3cfc0",
    });
  }, []);

  const searchCarDirect = (Model) => {
    props.history.push("/listings", {
      query: {
        makeId: "TESLA",
        modelId: Model,
        fromPrice: 0,
        toPrice: 0,
        zipCode: "75006",
        vehicleType: "USED",
      },
    });
  };

  const SearchCar = (query) => {
    // props.global.setLoader(true);
    query = {
      fromPrice: 1000,
      toPrice: 1750000,
      fromPrice: 0,
      toPrice: 0,
      zipCode: "75006",
      certified: false,
      vehicleType: "USED",
    };

    // axiosWithAuth()
    //   .post("/latest/api/vehicle/search/pricerange", query)
    //   .then((res) => {
    //     setData(res.data.items.slice(0, 3));
    //     props.global.setLoader(false);
    //   })
    //   .catch((res) => {
    //     props.global.setLoader(false);
    //   });

    axiosWithAuth()
      .post("/latest/api/vehicle/search/all", {
        makeId: "Tesla",
        // modelId: "Model 3",
      })
      .then((res) => {
        setData(res.data.items.slice(0, 6));
        props.global.setLoader(false);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
  };

  useEffect(() => {
    SearchCar();
  }, []);
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,

    speed: 500,
    slidesToShow:
      window.innerWidth > 1000
        ? 3
        : window.innerWidth < 1000 && window.innerWidth > 600
        ? 1
        : 1,
    slidesToScroll: 1,
    arrows: true,
  };
  return (
    <main>
      <Banner Title="Tesla Navigator" history={props.history} />
      <div className="popular-location border-bottom section-padding40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-tittle text-center mb-80">
                <h2>Tesla Models</h2>
                <p>View the Tesla Models we have available.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div
                className="single-location mb-30"
                onClick={() => {
                  searchCarDirect("Model X");
                }}
              >
                <div className="location-img">
                  <img src="assets/img/gallery/location1.png" alt="" />
                </div>
                <div className="location-details">
                  <p>Tesla Model X</p>
                  {/* <a href="#" className="location-btn">
                    65 <i className="ti-plus"></i> Listing
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div
                className="single-location mb-30"
                onClick={() => {
                  searchCarDirect("Model S");
                }}
              >
                <div className="location-img">
                  <img src="assets/img/gallery/location2.png" alt="" />
                </div>
                <div className="location-details">
                  <p>Tesla Modal S</p>
                  {/* <a href="#" className="location-btn">
                    60 <i className="ti-plus"></i> Listing
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div
                className="single-location mb-30"
                onClick={() => {
                  searchCarDirect("Model Y");
                }}
              >
                <div className="location-img">
                  <img src="assets/img/gallery/location3.png" alt="" />
                </div>
                <div className="location-details">
                  <p>Tesla Modal Y</p>
                  {/* <a href="#" className="location-btn">
                    50 <i className="ti-plus"></i> Listing
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div
                className="single-location mb-30 "
                onClick={() => {
                  searchCarDirect("Model 3");
                }}
              >
                <div className="location-img">
                  <img src="assets/img/gallery/location4.png" alt="" />
                </div>
                <div className="location-details">
                  <p>Tesla Modal 3</p>
                  {/* <a href="#" className="location-btn">
                    28 <i className="ti-plus"></i> Location
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div
                className="single-location mb-30"
                onClick={() => {
                  searchCarDirect("ROADSTER");
                }}
              >
                <div className="location-img">
                  <img src="assets/img/gallery/location5.png" alt="" />
                </div>
                <div className="location-details">
                  <p>Roadster</p>
                  {/* <a href="#" className="location-btn">
                    99 <i className="ti-plus"></i> Listing
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="popular-directorya-area  border-bottom section-padding40 fix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-tittle text-center mb-80">
                <h2>Popular Cars</h2>
                <p>See which Tesla’s are popular now.</p>
              </div>
            </div>
          </div>

          <Slider {...settings} style={{ padding: "0px 20px" }}>
            {data &&
              data.map((item, indexs) => {
                return (
                  <Cardview
                    key={item + indexs}
                    item={item}
                    history={props.history}
                  />
                );
              })}
            {data &&
              data.length < 3 &&
              data.map((item, indexs) => {
                return (
                  <Cardview
                    key={item + indexs}
                    item={item}
                    history={props.history}
                  />
                );
              })}
          </Slider>
        </div>
      </div>

      <section className="wantToWork-area">
        <div className="container">
          <div className="wants-wrapper w-padding2">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-7 col-lg-9 col-md-8">
                <div className="wantToWork-caption wantToWork-caption2">
                  <h2>How it works</h2>
                  <p>Let's uncover the best Tesla cars around you.</p>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                <a href="#" className="btn f-right sm-left">
                  Explore Tesla
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="our-services  border-bottom">
        <div className="container">
          <div className="row">
            <div className=" col-lg-4 col-md-6 col-sm-6">
              <div className="single-services mb-30">
                <div className="services-ion">
                  <span>01</span>
                </div>
                <div className="services-cap">
                  <h5>
                    <a href="#">Find Tesla Cars</a>
                  </h5>
                  <p>
                    Discover & connect with great local Tesla in your local
                    neighborhood and around you.
                  </p>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 col-sm-6">
              <div className="single-services mb-30">
                <div className="services-ion">
                  <span>02</span>
                </div>
                <div className="services-cap">
                  <h5>
                    <a href="#">List Your Tesla</a>
                  </h5>
                  <p>
                    List your Tesla car and make it sell through our platform.
                  </p>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 col-sm-6">
              <div className="single-services mb-30">
                <div className="services-ion">
                  <span>03</span>
                </div>
                <div className="services-cap">
                  <h5>
                    <a href="#">Make a Deal</a>
                  </h5>
                  <p>
                    Discover & connect with great local dealers in your local
                    neighborhood.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="popular-location border-bottom section-padding40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-tittle text-center mb-80">
                <h2>News & Updates</h2>
                <p>What’s new with Tesla.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="single-location mb-30">
                <div className="location-img">
                  <img src="assets/img/gallery/home-blog1.jpg" alt="" />
                </div>
                <div className="location-details">
                  <a href="#" className="location-btn">
                    Tips
                  </a>
                  <ul>
                    <li>12 March I by Alan </li>
                  </ul>
                  <br />
                  <p>
                    <a href="#"> The Best Tesla Car in City</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="single-location mb-30">
                <div className="location-img">
                  <img src="assets/img/gallery/home-blog2.jpg" alt="" />
                </div>
                <div className="location-details">
                  <a href="#" className="location-btn">
                    Tips
                  </a>
                  <ul>
                    <li>12 March I by Alan</li>
                  </ul>
                  <br />
                  <p>
                    <a href="#">
                      The World is looking for electric - Tesla Cars
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withGlobalContext(Home);
