import React, { useState, useEffect } from "react";
import Banner from "../Components/BannerTop";
import Cardview from "../Components/Cardview";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axiosWithAuth from "../utils/Services";
import { withGlobalContext } from "../utils/GlobalContext";
function Dealer(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");

  const DealerApi = () => {
    props.global.setLoader(true);

    axiosWithAuth()
      .post("/latest/api/registration/dealer/email", {
        name: name,
        email: email,
        contact: contact,
        zipCode: zipCode,
        city: city,
        state: state,
        address: address,
        subject: subject,
        message: message,
      })
      .then((res) => {
        alert("Message Sent.");
        props.global.setLoader(false);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
  };
  return (
    <main>
      <Banner Title="Dealers" NoSearch={true} />
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Dealers Registration </h2>
              <p>
                Sell your car at largest telsa Customer base, get registered
                now.
              </p>
            </div>
            <div className="col-lg-8">
              <form
                className="form-contact contact_form"
                onSubmit={(e) => {
                  e.preventDefault();
                  DealerApi();
                }}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        onChange={(e) => {
                          setName(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter Dealers name'"
                        placeholder="Enter Dealers name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        type="email"
                        onChange={(e) => {
                          setEmail(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter email address'"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="phone"
                        type="text"
                        onChange={(e) => {
                          setContact(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Phone no.'"
                        placeholder="Phone no."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        type="number"
                        onChange={(e) => {
                          setZipCode(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter Pincode'"
                        placeholder="Pincode"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        type="text"
                        onChange={(e) => {
                          setState(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter State'"
                        placeholder="State"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        cols="30"
                        rows="3"
                        onChange={(e) => {
                          setAddress(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter Complete Address'"
                        placeholder=" Enter Complete Address"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        onChange={(e) => {
                          setMessage(e.currentTarget.value);
                        }}
                        cols="30"
                        rows="7"
                        onfocus="this.placeholder = ''"
                        placeholder=" Enter Note (Optional)"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <button type="submit" className="btn ">
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-3 offset-lg-1">
              {/* <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-home"></i>
                </span>
                <div className="media-body">
                  <h3> Plano.</h3>
                  <p>Dallas, USA 91770</p>
                </div>
              </div> */}
              {/* <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-tablet"></i>
                </span>
                <div className="media-body">
                  <h3>+1 253 565 2365</h3>
                  <p>Mon to Fri 9am to 6pm</p>
                </div>
              </div> */}
              {/* <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-email"></i>
                </span>
                <div className="media-body">
                  <h3>support@teslanavigator.com</h3>
                  <p>Send us your query anytime!</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default withGlobalContext(Dealer);
