import React from "react";
//this is your global context that will be accessible everywhere in your app
import { Auth } from "aws-amplify";
const GlobalContext = React.createContext({});

//It is global context provider. Wrap it to the top level parent .. see App.js
export default class GlobalContextProvider extends React.Component {
  //These are the state that are Global. You can access it everywhere.
  //Create more such global state here
  state = {
    //user: null, //logged in user data //you can set is null or can give default value
    user: null, //default demo user
    isLoading: false, //if loader or spinner is there in the app
    allModals: [],
    make: [],
    Dealers: [],
  };
  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then((item) => {
        console.log(item);
        this.setState({ user: item });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ user: null });
      });
  }
  //This is the function to change the user session.
  //If user logged in your app you can set user Object here globally.
  createUserSession = (userdata) => {
    this.setState({ user: userdata });
  };

  //This is the function to set and remove loader from any page globally.
  //You can add more functions like these globally.
  setLoader = (show) => {
    this.setState({ isLoading: show });
  };

  setModals = (dat) => {
    this.setState({ allModals: dat });
  };
  setMake = (dat) => {
    this.setState({ make: dat });
  };
  setDealers = (dat) => {
    this.setState({ Dealers: dat });
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          createUserSession: this.createUserSession,
          setLoader: this.setLoader,
          setModals: this.setModals,
          setMake: this.setMake,
          setDealers: this.setDealers,
          //add all the global function declared above here
        }}
      >
        {/* This is your complete app i.e App.js */}
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

// This is consumer used to access global state in any child component
//Just bind the child with this object ... see Child.js and Spinner.js for more
export const withGlobalContext = (ChildComponent) => (props) => (
  <GlobalContext.Consumer>
    {(context) => <ChildComponent {...props} global={context} />}
  </GlobalContext.Consumer>
);
