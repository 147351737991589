import React, { useState, useEffect } from "react";
import Banner from "../Components/BannerTop";
import Cardview from "../Components/Cardview";
import "./Listing.css";
import S3FileUpload from "react-s3";
import { Accordion, Card } from "react-bootstrap";
import { withGlobalContext } from "../utils/GlobalContext";
import axiosWithAuth from "../utils/Services";

function Listing(props) {
  const dat = props.location.state ? props.location.state.dat : null;
  let userdetails = props.global.user;

  useEffect(() => {
    try {
      window.$(".fotorama").fotorama();
      window.scrollTo(0, 0);
    } catch (e) {}
  });
  const [formData, updateFormData] = useState({});
  const [imageList, UpdateImageList] = useState([]);
  useEffect(() => {
    window.$(".fotorama").fotorama();
    window.scrollTo(0, 0);
    let initialFormData = {
      aux: null,
      bestPrice: 0,
      body: dat.Body,
      bodyDoorCnt: dat.Body_Door_Cnt,
      bookValue: null,
      bottomPrice: 0,
      certificationName: null,
      certified: dat.Certified,
      cityMPG: dat.City_MPG,
      colour: dat.Colour,
      condition: dat.Condition,
      cost: 0,
      createBy: "TeslaApp_Admin",
      customDiscountAmt: 0,
      ddcDealerDiscounted: null,
      ddcGuaranteedRebates: null,
      ddcMisc: null,
      ddcOptionalRebates: null,
      ddcRW: null,
      ddcTotalRebates: null,
      dealerAddress: dat.Dealer_Email_Address,
      dealerCity: null,
      dealerDiscounted: null,
      dealerEmailAddress: dat.Dealer_Email_Address,
      dealerId: dat.Dealer_Id,
      dealerName: userdetails ? userdetails.attributes.name : "",
      dealerPhoneNumber: userdetails ? userdetails.attributes.phone_number : "",
      dealerRegion: null,
      zipCode: dat.Dealer_Zip_Code,
      description: dat.Description,
      dlrAccessories: null,
      drivetrainDesc: null,
      engineCylinderCnt: null,
      engineDisplacement: dat.Engine_Displacement,
      extColorCodes: dat.Ext_Color_Codes,
      features: dat.Features,
      guaranteedRebates: null,
      highwayMPG: null,
      inspectionChecklist: null,
      interiorColor: dat.Interior_Color,
      invoice: null,
      make: "TESLA",
      model: dat.Model.toUpperCase(),
      modelNumber: dat.ModelNumber,
      msrp: dat.MSRP,
      nadaValue: null,
      odometer: dat.Odometer,
      optionCodes: null,
      optionalRebates: null,
      otherPrice: 0,
      paymentDetails: dat.Payment_Details,
      photoCount: null,
      photoLastModifiedDate: null,
      photoUrlList: dat.Photo_Url_List,
      price: dat.Price,
      rw: null,
      series: null,
      seriesDetail: null,
      statusCode: null,
      stock: null,
      tags: null,
      totalRebates: null,
      transmission: dat.Transmission,
      videoPlayerUrl: null,
      videoUrl: null,
      vin: dat.Vin,
      wholesalePrice: 0,
      xStatus: null,
      year: dat.Year,
      userEmail: dat.Dealer_Email_Address,
    };

    updateFormData(initialFormData);
    UpdateImageList(
      initialFormData.photoUrlList
        ? initialFormData.photoUrlList.split(",")
        : []
    );
  }, [dat]);
  if (!dat) {
    props.history.push("mylistings");
    return <div />;
  }

  const handleSubmit = async (event) => {
    // event.preventDefault();

    props.global.setLoader(true);
    formData.photoUrlList = imageList.toString();
    axiosWithAuth()
      .post("/latest/api/vehicle/listing/update", formData)
      .then((res) => {
        console.log(res);
        props.global.setLoader(false);
        props.history.push("mylistings");
      })
      .catch((res) => {
        props.global.setLoader(false);
        alert("Some data is missing");
      });
  };

  const handleChange = async (e) => {
    e.preventDefault();
    if (e.target.name == "extColorCodes") {
      updateFormData({
        ...formData,

        // Trimming any whitespace
        [e.target.name]: e.target.value.trim(),
        ["colour"]: e.target.value.trim(),
      });
    } else {
      updateFormData({
        ...formData,

        // Trimming any whitespace
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const S3UploadFile = async (files, unitId) => {
    const config = {
      bucketName: "teslanavuseruploads",
      dirName: unitId /* optional */,
      region: "us-east-1",
      accessKeyId: "AKIA6QQNNUQ3R36DKGX3",
      secretAccessKey: "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",
    };
    let i = 0;
    for (i = 0; i < files.length; i++) {
      await S3FileUpload.uploadFile(files[i], config)
        .then((data) => {
          console.log(data);
          let daimag = imageList;
          daimag.unshift(data.location);

          UpdateImageList(daimag);
        })
        .catch((err) => {
          console.log(err);
          props.global.setLoader(false);
        });
      props.global.setLoader(false);
    }
  };
  return (
    <main>
      <div
        className="listing-area pt-180 "
        style={{
          background: "url(../../assets/img/gallery/tesla.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-xl-12 col-lg-12 col-md-12"
              style={{ marginBottom: "-87px" }}
            >
              <div
                className="category-listing mb-50"
                style={{
                  background: "white",
                }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="small-section-tittle2 mb-25">
                      <h1 className="contact-title mb-3">
                        Fill complete Details of Vehicle
                      </h1>
                      <h4>Find the perfect buyer.</h4>
                      Put NA for not Available.
                    </div>
                  </div>
                </div>
                <div className="row sellFrom">
                  <div className="col-md-8 col-sm-12 order-md-1 order-sm-2">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                          Car Basic Data
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="form-contact">
                              <div className="row">
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Zip Code
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="dealerZipCode"
                                      onChange={handleChange}
                                      type="text"
                                      defaultValue={formData.zipCode}
                                      placeholder="Enter zip code"
                                      autocomplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Modal
                                    </label>

                                    <select
                                      name="model"
                                      onChange={handleChange}
                                      required
                                      value={formData.model}
                                    >
                                      <option value="">Select Model</option>
                                      <option value="Model S">Model S</option>
                                      <option value="Model Y">Model Y</option>
                                      <option value="Model X">Model X</option>
                                      <option value="Model 3">Model 3</option>
                                      <option value="Roadster">Roadster</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label>Year</label>

                                    <select
                                      name="year"
                                      onChange={handleChange}
                                      value={formData.year}
                                    >
                                      <option value="">Select Year</option>
                                      <option value="2020">2020</option>
                                      <option value="2019">2019</option>
                                      <option value="2018">2018</option>
                                      <option value="2017">2017</option>
                                      <option value="2016">2016</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Model No.
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="modelNumber"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder="Enter Model no."
                                      autocomplete="off"
                                      defaultValue={formData.modelNumber}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Engine Displacement
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="engineDisplacement"
                                      onChange={handleChange}
                                      placeholder="Enter Engine type/ Displacement"
                                      autocomplete="off"
                                      defaultValue={formData.engineDisplacement}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Milage
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="cityMPG"
                                      type="number"
                                      onChange={handleChange}
                                      placeholder="Enter Milage"
                                      defaultValue={formData.cityMPG}
                                      autocomplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">VIN</label>
                                    <input
                                      class="form-control valid customInput"
                                      name="vin"
                                      onChange={handleChange}
                                      placeholder="Enter VIN"
                                      autocomplete="off"
                                      defaultValue={formData.vin}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                          Seller Story
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <div className="form-contact">
                              <div class="form-group">
                                <textarea
                                  class="form-control w-100 customInput"
                                  name="description"
                                  cols="30"
                                  onChange={handleChange}
                                  rows="5"
                                  placeholder=" Enter Car Description"
                                  defaultValue={formData.description}
                                ></textarea>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                          Equipment &amp; Options
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <div className="form-contact">
                              <div class="form-group">
                                <textarea
                                  class="form-control w-100 customInput"
                                  name="features"
                                  cols="30"
                                  onChange={handleChange}
                                  rows="5"
                                  defaultValue={formData.features}
                                  placeholder=" Enter Car features comma seperated"
                                ></textarea>
                                <small class="form-text text-muted">
                                  Enter Equipment and options with coma in
                                  between every equipment and options.
                                </small>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="3">
                          Car Details
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <div className="form-contact">
                              <div className="row">
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Odometer
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="odometer"
                                      type="number"
                                      onChange={handleChange}
                                      placeholder="Enter Odometer reading"
                                      autocomplete="off"
                                      defaultValue={formData.odometer}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Door Count
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="bodyDoorCnt"
                                      type="number"
                                      onChange={handleChange}
                                      placeholder="Enter Door count"
                                      defaultValue={formData.bodyDoorCnt}
                                    />
                                  </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Body Type
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="body"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder="Enter Body Type eg. 4D Sedan  "
                                      autocomplete="off"
                                      defaultValue={formData.body}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Transmission{" "}
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="transmission"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder="transmission"
                                      autocomplete="off"
                                      defaultValue={formData.transmission}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Interior color
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="interiorColor"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder="Interior color"
                                      autocomplete="off"
                                      defaultValue={formData.interiorColor}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Exterior Color
                                    </label>
                                    <input
                                      class="form-control valid customInput"
                                      name="extColorCodes"
                                      onChange={handleChange}
                                      placeholder="Enter Exterior Color"
                                      autocomplete="off"
                                      defaultValue={formData.colour}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <div
                      onClick={() => {
                        handleSubmit();
                      }}
                      className="col-6 p-3 pl-0"
                    >
                      <div className="btn">Update</div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 order-md-2 order-sm-1">
                    <b>Upload Car images</b>
                    <div className="custom-file mb-2 mt-2 ImagesUpload">
                      <div className="row" style={{ padding: "0px 15px" }}>
                        <div className="col-12">
                          <input
                            type="file"
                            className="custom-file-input "
                            onChange={async (e) => {
                              props.global.setLoader(true);

                              await S3UploadFile(e.target.files, formData.vin);
                            }}
                            multiple
                          />
                          <label
                            className="custom-file-label "
                            htmlFor="Apartment Image "
                          >
                            Add Car Images
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      id="carouselExampleIndicators"
                      class="carousel slide mt-25"
                    >
                      {/* <div className="fotorama" data-nav="thumbs">
                        {imageList.map((item) => {
                          return (
                            <img src={item} key={item} className="img-fluid" />
                          );
                        })}
                      </div> */}
                      {/* <img
                        src="images/default.png"
                        className="mb-20 img-fluid"
                      /> */}
                      <div className="col-md-12 mb-3 uploadImageBlock">
                        <div className="uploadImage">
                          <div className="imageBrowse">
                            <label htmlFor="List of Images">
                              List of Car Images
                            </label>
                          </div>
                          <div className="ImageList">
                            <div className="list-group">
                              <div className="row">
                                {imageList &&
                                  imageList.map((item, index) => {
                                    return (
                                      <div
                                        key={item}
                                        className="col-lg-4 col-6 col-sm-3 mb-3"
                                      >
                                        <div className="list-group-item">
                                          <img
                                            src={item != null ? item : ""}
                                            className="img-fluid"
                                          />
                                          <i
                                            onClick={(e) => {
                                              let img = [...imageList];

                                              if (
                                                window.confirm(
                                                  "Are you sure you want to delete this image?"
                                                )
                                              ) {
                                                if (img.length < 3) {
                                                  alert(
                                                    "Atleast two image required for Car listing."
                                                  );
                                                  return;
                                                }
                                                img.splice(index, 1);
                                                UpdateImageList(img);
                                              } else {
                                                // Do nothing!
                                              }
                                            }}
                                            className="fa fa-times closeCross"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withGlobalContext(Listing);
