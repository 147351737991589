import React, { useState } from "react";
import Slider from "react-slick";
import { withGlobalContext } from "../utils/GlobalContext";
var settings = {
  dots: false,
  autoplay: true,
  adaptiveHeight: true,
  infinite: true,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};
const getHttpsURl = (url) => {
  if (!url.includes("https")) {
    return url.replace("http", "https");
  }
  return url;
};
function CardView(props) {
  const { item } = props;
  let [show, setShow] = useState(false);
  return (
    <div
      className="properties style_prevu_kit properties2 mb-30 "
      onMouseOver={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
    >
      <div
        className="properties__card"
        onClick={() => {
          props.history.push("/cardetails", {
            item: item,
          });
        }}
      >
        <div className="properties__img overlay1">
          <a>
            {item && item.Photo_Url_List != "null" ? (
              <Slider {...settings}>
                {item.Photo_Url_List &&
                  item.Photo_Url_List.split(",").map((it, ind) => {
                    return <img src={getHttpsURl(it)} key={it + ind} alt="" />;
                  })}
              </Slider>
            ) : (
              <Slider {...settings}>
                <img src="images/default.png" alt="" />
              </Slider>
            )}
          </a>
          <div className="img-text">
            <span>{item && item.Condition == "U" ? "Used" : "New"}</span>
          </div>
          <div className="icon">
            <span className="card-notify-year">{item && item.Year}</span>
          </div>
        </div>
        <div className="properties__caption">
          <h3>
            {/* {props.global.make.find((x) => x.MakeId == item.Make_Id) &&
              props.global.make.find((x) => x.MakeId == item.Make_Id).Make} */}
            <a>{item && "Tesla " + item.Model + " " + item.ModelNumber}</a>
          </h3>
          {/* <div className="card-image-overlay m-auto">
            <span className="card-detail-badge">13000 Kms</span>
          </div> */}
          <p style={{ height: "45px" }}>
            {item && item.Description && item.Description.slice(0, 25)}
          </p>
          <div className="heart d-flex justify-content-between">
            <h3>${item && item.Price}</h3>

            {/* <img
              src="assets/img/gallery/heart1.png"
              alt=""
              style={{ pointerEvents: "none" }}
            /> */}
          </div>
        </div>
        {/* {show && (
          <div className="properties__footer d-flex justify-content-between align-items-center">
            <div className="restaurant-name">
              <a className="btn ad-btn" href="/cardetails">
                View
              </a>
            </div>
            <div className="heart">
              <img src="assets/img/gallery/heart1.png" alt="" />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default withGlobalContext(CardView);
