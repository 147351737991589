import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Pages/Home";
import Listing from "./Pages/Listing";
import SocialCards from "./Pages/SocialCards";
import CarDetails from "./Pages/CarDetails";
import SellCar from "./Pages/SellCar";
import MyListing from "./Pages/MyListing";
import SellFullForm from "./Pages/SellFullForm";
import Contact from "./Pages/Contact";
import DealerForm from "./Pages/DealerForm";
import WhyTesla from "./Pages/WhyTesla";
import Spinner from "./utils/spinner";
import GlobalContextProvider from "./utils/GlobalContext";
import ReactGA from "react-ga";
import "./Awsstyle.css";
import "crypto-js/lib-typedarrays";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

ReactGA.initialize("UA-178413242-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const signUpConfig = {
  header: (
    <div className="headerSignin">
      {/* <div>
        <img src={stayovrlogo} />
      </div> */}

      <h5 className="margin-top:10px">Sign Up</h5>
    </div>
  ),
  hideAllDefaults: true,
  defaultCountryCode: "1",
  signUpFields: [
    {
      label: "Your Business Email",
      key: "username",
      required: true,
      displayOrder: 1,
      type: "string",
    },
    {
      label: "Your name",
      key: "name",
      required: true,
      displayOrder: 5,
      type: "string",
    },
    {
      label: "Password",
      key: "password",
      required: true,
      displayOrder: 2,
      type: "password",
    },
    {
      label: "PhoneNumber",
      key: "phone_number",
      required: true,
      displayOrder: 3,
      type: "string",
    },
    {
      label: "Confirm Business Email",
      key: "email",
      required: true,
      displayOrder: 4,
      type: "string",
    },
  ],
};

function App() {
  const [LoginPopupShow, setLoginPopupShow] = useState(false);

  return (
    <>
      <GlobalContextProvider>
        <Router>
          <Spinner />
          <Header
            LoginPopupShow={LoginPopupShow}
            setLoginPopupShow={setLoginPopupShow}
          />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/social" component={SocialCards} />
            <Route exact path="/listings" component={Listing} />
            <Route exact path="/cardetails" component={CarDetails} />
            <Route
              exact
              path="/sellcar"
              component={(item) => {
                return (
                  <SellCar
                    LoginPopupShow={LoginPopupShow}
                    setLoginPopupShow={setLoginPopupShow}
                    history={item.history}
                  />
                );
              }}
            />
            <Route exact path="/sellerform" component={SellFullForm} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/dealer" component={DealerForm} />
            <Route exact path="/whytesla" component={WhyTesla} />
            <Route exact path="/mylistings" component={MyListing} />
            <Route component={Home} />
          </Switch>
          <Footer />
        </Router>

        <div id="back-top">
          <a title="Go to Top" href="#">
            <i className="fas fa-level-up-alt"></i>
          </a>
        </div>
      </GlobalContextProvider>
    </>
  );
}

// export default withAuthenticator(App, { signUpConfig });
export default App;
