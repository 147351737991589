import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { withGlobalContext } from "../utils/GlobalContext";
import axiosWithAuth from "../utils/Services";

function Banner(props) {
  const didMountRef = useRef(false);
  let [currentMake, SetCurrentMake] = useState("");
  let [currentModal, SetCurrentModal] = useState("");
  let [Modals, SetModals] = useState([]);
  useEffect(() => {
    if (didMountRef.current) {
    } else didMountRef.current = true;
  });

  useEffect(() => {
    axiosWithAuth()
      .get("/latest/api/vehicle/makes")
      .then((res) => {
        props.global.setMake(res.data.items);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });

    axiosWithAuth()
      .get("/latest/api/vehicle/dealers")
      .then((res) => {
        props.global.setDealers(res.data.items);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
    axiosWithAuth()
      .get("/latest/api/vehicle/models")
      .then((res) => {
        props.global.setModals(res.data.items);
        window.$("#makeselections").val("TESLA");
        SetCurrentMake("TESLA");
        let modals = res.data.items.filter((x) => x.Make_Id == "TESLA");
        SetModals(modals);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
  }, []);
  const SearchCar = (e) => {
    e.preventDefault();

    props.history.push("/listings", {
      query: {
        makeId: currentMake,
        modelId: currentModal.Model,
        fromPrice: 0,
        toPrice: 0,
        zipCode: window.$("#zipcode").val(),
        vehicleType: "USED",
      },
    });
  };

  const makeChange = (e) => {
    SetCurrentMake(e.currentTarget.value);
    let modals = props.global.allModals.filter(
      (x) => x.Make_Id == e.currentTarget.value
    );
    SetModals(modals);
  };

  const modalChange = (e) => {
    SetCurrentModal(
      props.global.allModals.find((x) => x.Model == e.currentTarget.value)
    );
  };

  if (props.NoSearch) {
    return (
      <div className="slider-area2">
        <div
          className={
            "slider-height3  hero-overly hero-bg4 d-flex align-items-center"
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap2 pt-20 text-center">
                  <h2>{props.Title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="slider-area hero-bg1 hero-overly">
        <div className="single-slider hero-overly  slider-height1 d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-10">
                <div className="hero__caption pt-100">
                  <h1>{props.Title}</h1>
                  <p>Let’s find the Tesla you’re looking for.</p>
                </div>
                <form
                  onSubmit={SearchCar}
                  className="search-box mb-100"
                  id="searchhome"
                >
                  <div
                    className="select-form col-lg-3 col-sm-6"
                    id="makeselect"
                  >
                    <select
                      name="select"
                      onChange={makeChange}
                      disabled={true}
                      required
                      id="makeselections"
                    >
                      <option value="">Tesla</option>

                      {props.global.make.map((item) => {
                        return (
                          <option key={item.Unique_Id} value={item.Unique_Id}>
                            {item.Make}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="select-form col-lg-3 col-sm-6">
                    <div className="select-itms" id="modelselect">
                      <select name="select" onChange={modalChange} required>
                        <option value="">Select Model</option>
                        {[...new Set(Modals.map((x) => x.Model))].map(
                          (item) => {
                            return (
                              <option
                                key={item.Make_Id + item.Unique_Id}
                                value={item}
                              >
                                {item}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="input-form col-lg-3 col-sm-6 ZipCodeInput">
                    <input
                      type="number"
                      id="zipcode"
                      placeholder="Zip code"
                      required
                    />
                  </div>
                  <div className="search-form SearchBtn col-lg-3 col-sm-6">
                    <button type="submit" className="btn">
                      <i className="ti-search"></i> Search
                    </button>
                  </div>
                </form>
                {/* <div className="category-img text-center">
                  <Link to="/sellcar">
                    <img src="assets/img/gallery/hero_category1.png" alt="" />
                  </Link>
                  <Link to="/listings">
                    <img src="assets/img/gallery/hero_category2.png" alt="" />
                  </Link>
                </div> */}

                <div class="tradingview-widget-container">
                  <div id="tradingview_3cfc0"></div>
                  <div class="tradingview-widget-copyright">
                    <a
                      href="https://www.tradingview.com/symbols/NASDAQ-TSLA/"
                      rel="noopener"
                      target="_blank"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGlobalContext(Banner);
