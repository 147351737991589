import React, { useState, useEffect } from "react";
import Banner from "../Components/BannerTop";
import Cardview from "../Components/Cardview";
import "./Listing.css";
import S3FileUpload from "react-s3";
import { withGlobalContext } from "../utils/GlobalContext";
import axiosWithAuth from "../utils/Services";

import CreditCardInput from "react-credit-card-input";
function Listing(props) {
  useEffect(() => {
    if (!props.global.user) {
      props.setLoginPopupShow(true);
      return;
    }
  }, []);

  let userdetails = props.global.user;

  const [payment, changePayment] = useState(false);
  const [paymentDetails, changePaymentDetails] = useState(false);
  const [vin, updateVin] = useState("");
  const [year, updateyear] = useState("");

  const [price, updatePrice] = useState("");
  const [milage, updatemilage] = useState("");
  const [engineType, updateengineType] = useState("");
  // const [make, updatemake] = useState("");
  const [model, updatemodel] = useState("");
  const [zipCode, updatezipCode] = useState("");
  const [cardtoken, updatecardtoken] = useState("");

  const [cardNumber, updatecardNumber] = useState("");
  const [cvc, updateCVC] = useState("");
  const [expiry, updateExpiry] = useState("");
  const [modelNumber, updatemodelNumber] = useState("");

  const handleCardNumberChange = (val) => {
    updatecardNumber(document.getElementById("card-number").value);
  };
  const handleCardExpiryChange = (val) => {
    updateExpiry(document.getElementById("card-expiry").value);
  };
  const handleCardCVCChange = (val) => {
    updateCVC(document.getElementById("cvc").value);
  };
  const MakePaymentCharged = async (token) => {
    await axiosWithAuth()
      .post(
        "/latest/api/stripe/charges",
        {
          total: 24.99,
          cardToken: token,
          userName: userdetails.attributes.name,
          vin: vin,
        },

        {
          headers: {
            "Content-Type": "application/json",

            // Token: userdetails.accessToken
          }, //refreshToken, idtoken,accessToken
        }
      )
      .then((response) => {
        if (
          response.status == "201" &&
          Object.entries(response.data).length > 0
        ) {
          console.log(response);

          let paymentDetailsStripe = response.data.data;
          axiosWithAuth()
            .post("/latest/api/vehicle/listing/add", {
              make: "Tesla",
              model: model,
              zipCode: zipCode,
              modelNumber: modelNumber,
              vin: vin,
              engineType: engineType,
              mileage: parseFloat(milage),
              year: year,
              price: price,
              userEmail: userdetails.attributes.email,
              paymentDetails: paymentDetailsStripe,
            })
            .then((res) => {
              console.log(res);
              props.global.setLoader(false);

              props.history.push("mylistings");
            })
            .catch((res) => {
              props.global.setLoader(false);
            });
        } else {
          alert("Card Declined, Please check your card and try again.");
          props.global.setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error.response);

        //this.SendErrorResponse(error);
        props.global.setLoader(false);

        return;
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let userdetails = props.global.user;

    if (cvc.length < 3 || cardNumber.length < 11 || expiry.length < 5) {
      alert("Invalid Card details, please check again!");

      return;
    }
    props.global.setLoader(true);

    await axiosWithAuth()
      .post(
        "/latest/api/stripe/token",
        {
          cardNumber: cardNumber,
          expiry: expiry,
          cvc: cvc,
        },

        {
          headers: {
            "Content-Type": "application/json",

            // Token: userdetails.accessToken
          }, //refreshToken, idtoken,accessToken
        }
      )
      .then((response) => {
        if (response.status == "201") {
          MakePaymentCharged(response.data.data.id);
        }
      })
      .catch((error) => {
        console.log(error.response);
        alert("Invalid Card details, please check again!");
        props.global.setLoader(false);

        return;
      });
  };

  const openPayment = () => {
    if (userdetails) {
      changePayment(true);
      return;
    }

    props.setLoginPopupShow(true);
  };
  return (
    <main>
      <div
        className="listing-area pt-180 SellCarFrom"
        style={{
          background: "url(../../assets/img/gallery/tesla.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-6"
              style={{ marginBottom: "-87px" }}
            >
              <div
                className="category-listing mb-50"
                style={{
                  background: "white",
                }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="small-section-tittle2 ">
                      <h1 class="contact-title mb-3">Sell Tesla Vehicle</h1>

                      <h4>
                        Find the perfect buyer. <br />
                        Put NA for non available data.
                      </h4>
                    </div>
                  </div>
                </div>
                <form
                  className="single-listing"
                  onSubmit={(e) => {
                    e.preventDefault();
                    openPayment();
                  }}
                >
                  <div className="select-job-items2 select-form2 ">
                    <div className="input-form">
                      <input
                        type="number"
                        required
                        placeholder="Zip code"
                        onChange={(e) => {
                          updatezipCode(e.currentTarget.value);
                        }}
                        className="customInput mb-0"
                      />
                    </div>
                    <div className="select-job-items2 select-form2 ">
                      <div className="input-form">
                        <input
                          type="text"
                          required
                          onChange={(e) => {
                            updateVin(e.currentTarget.value);
                          }}
                          placeholder="VIN No."
                          className="customInput mb-0"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="select-job-items2 select-form2 ">
                    <select
                      name="select2"
                      onChange={(e) => updatemodel(e.currentTarget.value)}
                      required
                    >
                      <option value="">Select Model</option>
                      <option value="Model S">Model S</option>
                      <option value="Model Y">Model Y</option>
                      <option value="Model X">Model X</option>
                      <option value="Model 3">Model 3</option>
                      <option value="Roadster">Roadster</option>
                    </select>
                  </div>
                  <div className="select-job-items2 select-form2 ">
                    <div className="input-form">
                      <input
                        type="text"
                        onChange={(e) => {
                          updatemodelNumber(e.currentTarget.value);
                        }}
                        placeholder="Model No."
                        className="customInput mb-0"
                        required
                      />
                    </div>
                  </div>
                  <div className="select-job-items2 select-form2 ">
                    <div className="input-form">
                      <input
                        type="number"
                        onChange={(e) => {
                          updatePrice(e.currentTarget.value);
                        }}
                        required
                        placeholder="Price"
                        className="customInput mb-0"
                      />
                    </div>
                  </div>
                  <div className="select-job-items1 select-form2 ">
                    <div className="select-job-items2 select-form2 ">
                      <div className="input-form">
                        <input
                          type="text"
                          required
                          onChange={(e) => {
                            updateengineType(e.currentTarget.value);
                          }}
                          placeholder="Engine Type / Displacement"
                          className="customInput mb-0"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="select-job-items1 select-form2 ">
                    <div style={{ width: "100%", display: "flex" }}>
                      <input
                        type="number"
                        required
                        placeholder="Milage"
                        className="customInput mb-0"
                        onChange={(e) => {
                          updatemilage(e.currentTarget.value);
                        }}
                      />
                      {/* <select name="select2">
                        <option value="">Clutch Type</option>
                        <option value="">1 speed Dual Clutch</option>
                      </select> */}
                      <div style={{ width: "10px" }} />
                      <select
                        name="select2"
                        onChange={(e) => {
                          updateyear(e.currentTarget.value);
                        }}
                        required
                      >
                        <option value="">Select Year</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1.3px solid #eee",
                      marginBottom: "12px",
                    }}
                  />
                  <div className="select-Categories pt-3">
                    <label className="container">
                      I'm still paying off this car (I have a lien)
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  <>
                    {payment && (
                      <form onSubmit={handleSubmit} className="paymentInputs">
                        <h1 className="mt-40 contact-title mb-3">
                          Payment Information
                        </h1>

                        <div className="">
                          <div className="select-job-items2 select-form2 ">
                            <div className="input-form">
                              <input
                                type="text"
                                placeholder="Card Holder Name"
                                id="Holdername"
                                className="customInput mb-1"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <CreditCardInput
                          cardNumberInputProps={{
                            // value: this.state.cardNumber,
                            onChange: (e) => handleCardNumberChange(e),
                          }}
                          cardExpiryInputProps={{
                            // value: this.state.expiry,
                            onChange: (e) => handleCardExpiryChange(e),
                          }}
                          cardCVCInputProps={{
                            // value: this.state.cvc,
                            onChange: (e) => handleCardCVCChange(e),
                          }}
                          fieldClassName="input"
                          containerClassName="CreditInputContainer select-job-items2 select-form2 "
                        />
                        {payment && (
                          <div className="input-group-append apply_btn">
                            <button
                              // type="submit"
                              className="btn w-100 mt-4 "
                              // disabled={!stripe}

                              style={{ marginTop: "10px" }}
                            >
                              Proceed to pay $24.99
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </>
                  {!payment && (
                    <button type="submit" className="btn w-100 mt-4">
                      List My Car
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="count mb-35"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withGlobalContext(Listing);
