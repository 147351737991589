import React from "react";

import { Authenticator } from "aws-amplify-react";
import { Auth } from "aws-amplify";

import "@aws-amplify/ui/dist/style.css";
import { withGlobalContext } from "./utils/GlobalContext";
import axiosWithAuth from "./utils/Services";

const AuthForm = (props) => {
  // Removed full screen width/height issues without removing error alerts appended to top of form.
  const AuthFix = {
    formContainer: { margin: 0 },
    formSection: { margin: 0 },
  };

  async function handleAuthStateChange(state) {
    if (state === "signedIn") {
      Auth.currentAuthenticatedUser()
        .then((item) => {
          axiosWithAuth()
            .post("/latest/api/vehicle/user", {
              userName: item.username,
              name: item.attributes.name,
              phone: item.attributes.phone_number,
              email: item.attributes.email,
            })
            .then((res) => {
              props.global.createUserSession(item);
              props.global.setLoader(false);
            })
            .catch((res) => {
              props.global.setLoader(false);
            });
        })
        .catch((err) => {
          props.global.createUserSession(null);
        });

      props.onHide(false);
    }
  }
  const signUpConfig = {
    hideAllDefaults: true,
    defaultCountryCode: "1",
    authConfig: {
      usernameAttributes: "email",
    },
    signUpFields: [
      {
        label: "Username",
        key: "username",
        required: true,
        displayOrder: 1,
        type: "string",
      },
      {
        label: "Your name",
        key: "name",
        required: true,
        displayOrder: 3,
        type: "string",
      },
      {
        label: "Password",
        key: "password",
        required: true,
        displayOrder: 5,
        type: "password",
      },
      {
        label: "PhoneNumber",
        key: "phone_number",
        required: true,
        displayOrder: 4,
        type: "string",
      },
      {
        label: "Email",
        key: "email",
        required: true,
        displayOrder: 2,
        type: "email",
      },
    ],
  };

  return (
    <Authenticator
      onStateChange={handleAuthStateChange}
      signUpConfig={signUpConfig}
    />
  );
};

export default withGlobalContext(AuthForm);
