import React, { useEffect, useState, useRef } from "react";
import Banner from "../Components/BannerTop";
import { useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Facebook, Twitter, Reddit, Email } from "react-sharingbuttons";
import "react-sharingbuttons/dist/main.css";

import { TwitterTimelineEmbed } from "react-twitter-embed";
// import youtube from "../YoutubeAPi";
const KEY = "AIzaSyA-CsrD8nYtMiccC8yngaZiCtJoFJvw4Ng";

const url = "https://teslanavigator.com";
const shareText = "Check out the Tesla Car at TeslaNavigator.com.";
const sharingButtons = () => {
  return (
    <div
      className="d-flex share justify-content-center"
      style={{ alignItems: "center" }}
    >
      Share:
      <Facebook url={url} />
      <Twitter url={url} shareText={shareText} />
      <Reddit url={url} text="Reddit" />
      <Email text="Email " url={url} subject={shareText} />
    </div>
  );
};
const TwitterTimeline = (handle) => {
  return (
    <TwitterTimelineEmbed
      sourceType="profile"
      screenName={handle}
      options={{ height: 400 }}
    />
  );
};

const GetVideo = (vidId) => {
  return (
    <div
      onClick={() => {
        var popUp = window.$(".popup-video");
        if (popUp.length) {
          popUp.magnificPopup({
            items: [
              {
                src: "https://www.youtube.com/watch?v=" + vidId + "?autoplay=1",
                type: "iframe",
              },
            ],
          });
        }
      }}
    >
      <iframe
        width="380"
        height="350"
        src={"https://www.youtube.com/embed/" + vidId}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ pointerEvents: "none" }}
      ></iframe>
    </div>
  );
};

const ChannelIds = [
  "UC9FCbOYPZl6QhXgxj_MGRkw",
  "UCJecS3QabTtjp3owlMAdl4w",
  "UCgY_B6vi2610bBkXFwCxcTg",
  "UCkcpAX1PqQWcR44D19kVX7Q",
  "UCgYkuL87rUwiBl7tqfN53Eg",
  "UCNMXmoTyxHeaHtd0MlHysdA",
];

function SocialCards() {
  let location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const youtuberef = useRef(null);
  const fbref = useRef(null);
  const twitterref = useRef(null);
  useEffect(() => {
    if (location.search.slice(1) == "fb") {
      scrollToRef(fbref);
    }
    if (location.search.slice(1) == "youtube") {
      scrollToRef(youtuberef);
    }
    if (location.search.slice(1) == "twitter") {
      scrollToRef(twitterref);
    }
  });
  const [videos, setVideos] = useState([
    "9NtqgEG7Qc",
    "EdmHNHMY5Ks",
    "kQb7tFNr-pM",
    "LbAgurCkA5U",
    "GYmH7575ly0",
  ]);
  const [twitter, setTwitter] = useState([
    "Tesla",
    "teslatidbits",
    "TheTeslaLife",
    "talkingtesla",
    "LikeTeslaKim",
    "Teslarati",
    "TheTeslaShow",
    "TeslaPodcast",
  ]);

  const [insta, setInstagram] = useState([
    "Tesla",
    "teslatidbits",
    "TheTeslaLife",
    "talkingtesla",
    "LikeTeslaKim",
    "Teslarati",
    "TheTeslaShow",
    "TeslaPodcast",
  ]);

  const [fb, setFb] = useState([
    "teslacarsclub",
    "afto.in",
    "ElonMusk.SpaceX.Tesla",
  ]);

  // useEffect(async () => {
  //   await ChannelIds.map(async (id) => {
  //     const response = await youtube.get("/search", {
  //       params: {
  //         //q: termFromSearchBar
  //         part: "snippet",
  //         maxResults: 1,
  //         key: KEY,
  //         order: "date",
  //         channelId: id,
  //       },
  //     });
  //     setVideos([...videos, response.data.items[0].id.videoId]);
  //   });
  // }, []);

  const GetFBPAGE = (userId) => {
    return (
      <div
        className="fb-page"
        data-href={"https://www.facebook.com/" + userId}
        data-tabs="timeline"
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite={"https://www.facebook.com/" + userId}
          className="fb-xfbml-parse-ignore"
        >
          <a href={"https://www.facebook.com/" + userId}>{userId}</a>
        </blockquote>
      </div>
    );
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <>
      <Banner NoSearch={true} Title="Social cards" />
      <div ref={fbref}>
        <div className="popular-directorya-area  border-bottom section-padding40 fix">
          <div className="container mt-30">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-tittle text-center mb-80">
                  <h2>Fabebook Pages</h2>
                  <p>Let's uncover the top Tesla Influencers.</p>
                </div>
              </div>
            </div>
            <Slider {...settings}>
              {" "}
              {fb
                .sort(() => Math.random() - 0.5)
                .map((item) => {
                  return <div key={item}>{GetFBPAGE(item)} </div>;
                })}
            </Slider>
          </div>
        </div>{" "}
        {sharingButtons()}
      </div>
      <div ref={youtuberef}>
        <div className="popular-directorya-area  border-bottom section-padding40 fix">
          <div className="container mt-30">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-tittle text-center mb-80">
                  <h2>Youtube Pages</h2>
                  <p>Let's uncover the top Tesla Influencers.</p>
                </div>
              </div>
            </div>
            <Slider {...settings}>
              {" "}
              {videos
                .sort(() => Math.random() - 0.5)
                .map((item) => {
                  return (
                    <div key={item} className="popup-video">
                      {GetVideo(item)}
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>{" "}
        {sharingButtons()}
      </div>
      <div ref={twitterref}>
        <div className="popular-directorya-area  border-bottom section-padding40 fix">
          <div className="container mt-30">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-tittle text-center mb-80">
                  <h2>Twitter Pages</h2>
                  <p>Let's uncover the top Tesla Influencers.</p>
                </div>
              </div>
            </div>
            <Slider {...settings}>
              {" "}
              {twitter
                .sort(() => Math.random() - 0.5)
                .map((ite) => {
                  return <div key={ite}>{TwitterTimeline(ite)}</div>;
                })}
            </Slider>
          </div>{" "}
        </div>{" "}
        {sharingButtons()}
      </div>{" "}
    </>
  );
}

export default SocialCards;
