import React, { useState, useEffect } from "react";
import Banner from "../Components/BannerTop";
import axios from "axios";
import "./Listing.css";
import axiosWithAuth from "../utils/Services";
import "./CarDetails.css";

import { withGlobalContext } from "../utils/GlobalContext";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Cardetails(props) {
  console.log(props.location.state);
  let userdetails = props.global.user;
  let allCardetails = props.location.state;
  let allmake = props.global.make;
  const [ArrayformData, updateArrayformData] = React.useState({
    dealerEmail: props.location.state
      ? props.location.state.item.Dealer_Email_Address
      : "",

    vehicleDetails: props.location.state ? props.location.state.item : {},
    userName: userdetails ? userdetails.attributes.name : "",
    userEmail: userdetails ? userdetails.attributes.email : "",
    userContact: userdetails ? userdetails.attributes.phone_number : "",
    userZipCode: "",
    message: "I'd like to know your best price for this ",
  });

  const getHttpsURl = (url) => {
    if (!url.includes("https")) {
      return url.replace("http", "https");
    }
    return url;
  };
  const [showMore, UpdateShowMore] = React.useState(false);

  const handleDataChange = (e, index) => {
    e.preventDefault();

    let ArrayformDataTemp = Object.assign({}, ArrayformData);

    ArrayformDataTemp[e.target.name] = e.target.value.trim();

    updateArrayformData(ArrayformDataTemp);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );
  useEffect(() => {
    updateArrayformData({
      dealerEmail: props.location.state
        ? props.location.state.item.Dealer_Email_Address
        : "",

      vehicleDetails: props.location.state ? props.location.state.item : {},
      userName: userdetails ? userdetails.attributes.name : "",
      userEmail: userdetails ? userdetails.attributes.email : "",
      userContact: userdetails ? userdetails.attributes.phone_number : "",
      userZipCode: "777777",
      message: "I'd like to know your best price for this ",
    });
  }, [props.global.user]);
  useEffect(() => {
    window.$(".fotorama").fotorama({
      width: "100%",

      allowfullscreen: true,
      nav: "thumbs",
    });
    window.scrollTo(0, 0);

    axiosWithAuth()
      .get("/latest/api/vehicle/makes")
      .then((res) => {
        props.global.setMake(res.data.items);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
  }, []);

  if (!props.location.state) {
    props.history.push("/listings");
    return <div />;
  }
  let carName =
    allmake.find((x) => x.MakeId == allCardetails.item.Make_Id) &&
    allmake.find((x) => x.MakeId == allCardetails.item.Make_Id).Make;

  return (
    <main>
      <Banner Title="Car details" NoSearch={true} />

      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="modelImages   my-5">
              <div id="carouselExampleIndicators" class="carousel slide">
                <div className="fotorama" data-nav="thumbs" data-fit="cover">
                  {allCardetails.item &&
                  allCardetails.item.Photo_Url_List != "null" ? (
                    allCardetails.item.Photo_Url_List &&
                    allCardetails.item.Photo_Url_List.length > 0 &&
                    allCardetails.item.Photo_Url_List.split(",").map((ite) => {
                      return <img src={getHttpsURl(ite)} />;
                    })
                  ) : (
                    <img src="images/default.png" />
                  )}
                </div>
              </div>
              <div className="ModelName mt-2">
                <div className="d-flex justify-content-between  mt-5">
                  <b>{`Tesla ${allCardetails.item.Model} ${allCardetails.item.ModelNumber} - ${allCardetails.item.Year}`}</b>
                  <b
                    style={{ color: "#ff4431" }}
                  >{`$ ${allCardetails.item.Price} `}</b>
                </div>
                <p>
                  <span style={{ fontWeight: "bold" }}>VIN : </span>
                  {allCardetails.item.Vin}
                </p>

                {/* <div class="product-links">
                  <a href="">
                    <i class="fa fa-heart"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-shopping-cart"></i>
                  </a>
                </div> */}
              </div>
            </div>
            <div className=" my-5">
              <h3 className="modelHighletsHeading">Car Details</h3>
              <div className="modelHighlets row mt-5">
                <div className="MHighlet col-md-2 col-sm-6  col-6  text-center p-5">
                  <div className="MhighletImg">
                    <img
                      src="../../assets/img/gallery/carMiles.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="MhighletH mt-3">
                    {" "}
                    {allCardetails.item &&
                      (allCardetails.item.Odometer == "null"
                        ? "NA"
                        : allCardetails.item.Odometer + " Miles")}
                  </div>
                </div>

                <div className="MHighlet col-md-2 col-sm-6 col-6  text-center p-5">
                  <div className="MhighletImg">
                    <img
                      src="../../assets/img/gallery/carClutch.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="MhighletH mt-3">
                    {" "}
                    {allCardetails.item &&
                      (allCardetails.item.Transmission == "null"
                        ? "NA"
                        : allCardetails.item.Transmission)}
                  </div>
                </div>
                <div className="MHighlet col-md-2 col-sm-6 col-6  text-center p-5">
                  <div className="MhighletImg">
                    <img
                      src="../../assets/img/gallery/carMPG.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="MhighletH mt-3">
                    {" "}
                    {allCardetails.item &&
                      (allCardetails.item.City_MPG == "null"
                        ? "NA"
                        : allCardetails.item.City_MPG)}
                  </div>
                </div>
                <div className="MHighlet col-md-2 col-sm-6 col-6  text-center p-5">
                  <div className="MhighletImg">
                    <img
                      src="../../assets/img/gallery/carSeat.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="MhighletH mt-3">
                    {allCardetails.item &&
                      (allCardetails.item.Interior_Color == "null"
                        ? "NA"
                        : allCardetails.item.Interior_Color)}
                  </div>
                </div>

                <div className="MHighlet col-md-2 col-sm-6 col-6  text-center p-5">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 0 }}
                    overlay={renderTooltip}
                  >
                    <>
                      <div className="MhighletImg">
                        <img
                          src="../../assets/img/gallery/carColor.png"
                          className="img-fluid"
                        />
                      </div>
                      <div className="MhighletH mt-3">
                        {allCardetails.item &&
                          (allCardetails.item.Colour == "null"
                            ? "NA"
                            : allCardetails.item.Colour)}
                      </div>
                    </>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="CarDetails my-5">
              <h3 className="modelHighletsHeading">SELLER STORY</h3>
              <p>{allCardetails.item.Description}</p>
            </div>
            <div className="EqupOpt my-5">
              <h3 className="modelHighletsHeading">Vehicle Info</h3>

              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <table class="table">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td className="greyData">VIN</td>
                        <td>{allCardetails.item.Vin}</td>
                      </tr>
                      <tr>
                        <td className="greyData">Dealer</td>
                        <td>{allCardetails.item.Dealer_Name}</td>
                      </tr>
                      <tr>
                        <td className="greyData">Type</td>
                        <td>
                          {allCardetails.item.Condition == "U" ? "Used" : "New"}
                        </td>
                      </tr>
                      <tr>
                        <td className="greyData">Year</td>
                        <td>{allCardetails.item.Year}</td>
                      </tr>
                      <tr>
                        <td className="greyData">Make</td>
                        <td>Tesla</td>
                      </tr>
                      <tr>
                        <td className="greyData">Model</td>
                        <td>{allCardetails.item.Model}</td>
                      </tr>
                      <tr>
                        <td className="greyData">Odometer</td>
                        <td>{allCardetails.item.Odometer}</td>
                      </tr>
                      <tr>
                        <td className="greyData">Trim</td>
                        <td>{allCardetails.item.ModelNumber}</td>
                      </tr>

                      <tr>
                        <td className="greyData">Body Style</td>
                        <td>{allCardetails.item.Body}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xs-12 col-md-6">
                  <table class="table">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td className="greyData">Transmission</td>
                        <td> {allCardetails.item.Transmission}</td>
                      </tr>
                      <tr>
                        <td className="greyData">Engine type</td>
                        <td>
                          {" "}
                          {allCardetails.item &&
                          allCardetails.item.Engine_Displacement == "null"
                            ? "NA"
                            : allCardetails.item.Engine_Displacement}
                        </td>
                      </tr>
                      <tr>
                        <td className="greyData">Door</td>
                        <td>
                          {allCardetails.item &&
                            allCardetails.item.Body_Door_Cnt}
                        </td>
                      </tr>
                      <tr>
                        <td className="greyData">Exterior Color</td>
                        <td>
                          {" "}
                          {allCardetails.item &&
                            (allCardetails.item.Colour == "null"
                              ? "NA"
                              : allCardetails.item.Colour)}
                        </td>
                      </tr>
                      <tr>
                        <td className="greyData">Interior Color</td>
                        <td>
                          {allCardetails.item &&
                            allCardetails.item.Interior_Color}
                        </td>
                      </tr>
                      <tr>
                        <td className="greyData">Fuel Type</td>
                        <td>Electric</td>
                      </tr>
                      <tr>
                        <td className="greyData">EPA City</td>
                        <td>
                          {allCardetails.item && allCardetails.item.City_MPG}
                        </td>
                      </tr>
                      <tr>
                        <td className="greyData">EPA Highway</td>
                        <td>
                          {allCardetails.item && allCardetails.item.Highway_MPG}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="EqupOpt my-5">
              <h3 className="modelHighletsHeading">EQUIPMENT &amp; OPTIONS</h3>
              <ul>
                {showMore &&
                  allCardetails.item.Features &&
                  allCardetails.item.Features.split(",").map((it) => {
                    return (
                      <li key={it}>
                        <i class="fas fa-angle-right mr-2"></i>
                        {it}
                      </li>
                    );
                  })}

                {!showMore &&
                  allCardetails.item.Features &&
                  allCardetails.item.Features.split(",")
                    .slice(0, 8)
                    .map((it) => {
                      return (
                        <li key={it}>
                          <i class="fas fa-angle-right mr-2"></i>
                          {it}
                        </li>
                      );
                    })}
                {!showMore &&
                  allCardetails.item.Features &&
                  allCardetails.item.Features.split(",").length > 8 && (
                    <li>
                      <p
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => {
                          UpdateShowMore(true);
                        }}
                      >
                        <i class="fas fa-angle-right mr-2"></i>
                        show more ...
                      </p>
                    </li>
                  )}

                {showMore && allCardetails.item.Features.split(",").length > 8 && (
                  <li>
                    <p
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => {
                        UpdateShowMore(false);
                      }}
                    >
                      <i class="fas fa-angle-right mr-2"></i>
                      show less ...
                    </p>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="col-md-4 mt-5 productPosition">
            <div class="product-card">
              <div class="badge">Tesla</div>
              <div class="product-tumb">
                <img
                  src={
                    allCardetails.item &&
                    allCardetails.item.Photo_Url_List != "null"
                      ? allCardetails.item.Photo_Url_List.length > 0 &&
                        getHttpsURl(
                          allCardetails.item.Photo_Url_List.split(",")[0]
                        )
                      : "images/default.png"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div class="product-details">
                <span class="product-catagory">{allCardetails.item.Year}</span>
                <h4 className="modelHighletsHeading mb-4">
                  {" "}
                  {`Tesla ${allCardetails.item.Model} ${allCardetails.item.ModelNumber} `}
                </h4>
                <p>
                  {allCardetails.item &&
                    allCardetails.item.Description &&
                    allCardetails.item.Description.slice(0, 160)}
                </p>
                <h3 className="modelHighletsHeading">Dealer Information</h3>
                <div className="  d-flex justify-content-between mt-4">
                  <p className="Spctitle p-0 m-1">
                    {allCardetails.item.Dealer_Name}
                  </p>
                </div>
                <div className="d-flex justify-content-between ">
                  <p className=" p-0 m-1">
                    {allCardetails.item.Dealer_Address +
                      " " +
                      allCardetails.item.Dealer_City +
                      " " +
                      allCardetails.item.Dealer_Region}
                  </p>
                </div>
                <div className="d-flex justify-content-between ">
                  <p className="Spctitle p-0 m-1">Email</p>
                  <p className=" p-0 m-1">
                    {allCardetails.item.Dealer_Email_Address}
                  </p>
                </div>
                <div className="d-flex justify-content-between ">
                  <p className="Spctitle p-0 m-1"> Phone </p>
                  <p className=" p-0 m-1">
                    {allCardetails.item.Dealer_Phone_Number}
                  </p>
                </div>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.global.setLoader(true);

                axiosWithAuth()
                  .post("/latest/api/vehicle/dealer/email", ArrayformData)
                  .then((dat) => {
                    console.log(dat);
                    alert("Message sent Successfully.");
                    props.global.setLoader(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    props.global.setLoader(false);
                  });
              }}
              className="product-card p-4 mt-4"
              style={{ background: "#fbf9f9" }}
            >
              <p
                style={{
                  lineHeight: "3.6rem",

                  wordSpacing: "0.2rem",
                  letterSpacing: "1px",
                }}
              >
                <h1 style={{ fontWeight: "bold", marginBottom: "22px" }}>
                  Contact Dealer
                </h1>
                <p>
                  Hello my name is
                  <input
                    type="text"
                    name="userName"
                    placeholder="Name"
                    style={{
                      width: "150px",
                      marginLeft: "12px",
                      marginRight: "10px",
                      padding: "0px 0px",
                      height: "30px",
                      paddingLeft: "10px",
                    }}
                    onChange={(e, index) => {
                      handleDataChange(e, index);
                    }}
                    required
                    defaultValue={userdetails && userdetails.attributes.name}
                  />
                  and
                  <select
                    onChange={(e, index) => {
                      handleDataChange(e, index);
                    }}
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "200px",
                      height: "28px",
                      padding: "0px 0px",
                      height: "30px",
                      borderRadius: 0,
                      borderColor: "rgb(118, 118, 118)",
                    }}
                    name="message"
                    required
                  >
                    <option value="I'd like to know your best price for this ">
                      I'd like to know your best price for this
                    </option>
                    <option value="I'd like to test drive this">
                      I'd like to test drive this
                    </option>
                    <option value="I'm interested in this">
                      I'm interested in this
                    </option>
                    <option value="I'd like a history report for this vehicle">
                      I'd like a history report for this vehicle
                    </option>
                  </select>
                  in{" "}
                  <b>{`  ${allCardetails.item.Year} Tesla ${allCardetails.item.ModelNumber}`}</b>
                  . I'm in{" "}
                  <input
                    style={{
                      height: "28px",
                      paddingLeft: "10px",
                      width: "100px",
                    }}
                    placeholder="Zip Code"
                    className=""
                    type="number"
                    name="userZipCode"
                    onChange={(e, index) => {
                      handleDataChange(e, index);
                    }}
                    required
                  />{" "}
                  area.
                </p>
                <p>
                  You can reach me by email at
                  <input
                    style={{
                      height: "28px",
                      width: "180px",
                      paddingLeft: "10px",
                    }}
                    name="userEmail"
                    onChange={(e, index) => {
                      handleDataChange(e, index);
                    }}
                    placeholder="Email"
                    defaultValue={userdetails && userdetails.attributes.email}
                    className=""
                    required
                  />{" "}
                  or by phone at{" "}
                  <input
                    style={{ height: "28px", paddingLeft: "10px" }}
                    placeholder="Phone Number"
                    className=""
                    name="userContact"
                    onChange={(e, index) => {
                      handleDataChange(e, index);
                    }}
                    required
                    defaultValue={
                      userdetails && userdetails.attributes.phone_number
                    }
                  />
                  <br />{" "}
                </p>
                Thank you. <br />
                <span className="d-flex">
                  <input
                    type="checkbox"
                    className="mr-2 mt-3"
                    id="checkEmail"
                    onChange={(e, index) => {
                      handleDataChange(e, index);
                    }}
                  />
                  <label for="checkEmail">
                    Email me price drop and new listing for my search.
                  </label>
                </span>
              </p>
              <button type="submit" className=" btn">
                Message Seller
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withGlobalContext(Cardetails);
