import React, { useState, useEffect, useRef } from "react";
import Banner from "../Components/BannerTop";
import Cardview from "../Components/Cardview";

import "./Listing.css";
import { withGlobalContext } from "../utils/GlobalContext";
import axiosWithAuth from "../utils/Services";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";

function Listing(props) {
  const [data, setData] = useState([]);
  const [rangeStart, setRangeStart] = useState(1000);
  const [rangeEnd, setRangeEnd] = useState(150000);
  const [Certified, setCertified] = useState(false);
  const [radius, setRadius] = useState("");
  let [currentMake, SetCurrentMake] = useState("Tesla");
  let [currentModal, SetCurrentModal] = useState("");
  let [Modals, SetModals] = useState([]);
  const didMountRef = useRef(false);
  useEffect(() => {
    const q = props.location.state ? props.location.state.query : {};

    if (didMountRef.current) {
    } else didMountRef.current = true;
  });
  useEffect(() => {
    props.global.setLoader(true);
    const q = props.location.state ? props.location.state.query : {};

    axiosWithAuth()
      .get("/latest/api/vehicle/makes")
      .then((res) => {
        props.global.setMake(res.data.items);
        SetCurrentMake(q.makeId);

        window.$("#selectmake").val(q.makeId);
        axiosWithAuth()
          .get("/latest/api/vehicle/models")
          .then((res) => {
            props.global.setModals(res.data.items);
            let allcurrmodals = res.data.items.filter(
              (x) => x.Make_Id == q.makeId
            );
            SetModals(allcurrmodals);
            SetCurrentModal(q.modelId);

            window.$("#selectmodal").val(q.modelId);
            window.$("#makeselections").val("TESLA");
            SetCurrentMake("TESLA");
            let modals = res.data.items.filter((x) => x.Make_Id == "TESLA");
            SetModals(modals);
          })
          .catch((res) => {
            props.global.setLoader(false);
          });
      })
      .catch((res) => {
        props.global.setLoader(false);
      });

    axiosWithAuth()
      .get("/latest/api/vehicle/dealers")
      .then((res) => {
        props.global.setDealers(res.data.items);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
    props.global.setLoader(false);
  }, []);

  useEffect(() => {
    const q = props.location.state ? props.location.state.query : {};
    SearchCar(q);
    window.$("#zipcode").val(q && q.zipCode);
  }, []);

  const SearchCar = (query) => {
    const q = props.location.state ? props.location.state.query : {};

    props.global.setLoader(true);
    if (!query) {
      query = {
        makeId: currentMake,
        modelId: currentModal,
        certified: Certified,
        fromPrice: 0,
        toPrice: 0,
        zipCode: window.$("#zipcode").val(),
        vehicleType: "USED", //u
      };
    }

    let url = "/latest/api/vehicle/search";

    // if (!currentMake || !currentModal) {
    //   url = "/latest/api/vehicle/search/pricerange";
    // }
    if (Object.keys(q).length == 0 && !currentModal && currentModal == "") {
      axiosWithAuth()
        .post("/latest/api/vehicle/search/all", {
          makeId: currentMake,
          modelId: currentModal != "" ? currentModal : null,
        })
        .then((res) => {
          setData(res.data.items);
          props.global.setLoader(false);
        })
        .catch((res) => {
          props.global.setLoader(false);
        });
      return;
    }

    if (
      rangeStart > 1000 ||
      rangeEnd < 150000 ||
      Object.keys(query).length == 0
    ) {
      query = {
        makeId: currentMake,
        modelId: currentModal != "" ? currentModal : null,
        fromPrice: rangeStart,
        toPrice: rangeEnd,
        certified: Certified,
        vehicleType: "USED",
        zipCode: window.$("#zipcode").val()
          ? window.$("#zipcode").val()
          : "75006",
      };
    }

    if (radius != "") {
      url = "/latest/api/vehicle/search/distance";
      query = {
        makeId: currentMake,
        modelId: currentModal != "" ? currentModal : null,
        fromPrice: rangeStart,
        toPrice: rangeEnd,
        certified: Certified,
        distance: parseInt(radius),
        vehicleType: "USED",
        zipCode: window.$("#zipcode").val()
          ? window.$("#zipcode").val()
          : "75006",
      };
    }

    axiosWithAuth()
      .post(url, query)
      .then((res) => {
        setData(res.data.items);
        if (res.data.items == null || res.data.items.length == 0) {
          props.global.setLoader(true);

          if (!Certified) {
            axiosWithAuth()
              .post("/latest/api/vehicle/search/all", query)
              .then((res) => {
                setData(res.data.items);
                props.global.setLoader(false);
              })
              .catch((res) => {
                props.global.setLoader(false);
              });
          } else {
            props.global.setLoader(false);
          }
        } else {
          props.global.setLoader(false);
        }
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
  };

  const onSliderChange = (value) => {
    console.log(value);
    setRangeStart(value[0]);
    setRangeEnd(value[1]);
  };

  const makeChange = (e) => {
    const q = props.location.state ? props.location.state.query : {};

    SetCurrentMake(e.currentTarget.value);
    let tem = props.global.allModals.filter(
      (x) => x.Make_Id == e.currentTarget.value
    );
    SetModals(tem);
  };
  const CertifiedCheck = (e) => {
    setCertified(e.target.checked);
  };
  const modalChange = (e) => {
    let tem = props.global.allModals.find(
      (x) => x.Model == e.currentTarget.value
    );
    SetCurrentModal(tem && tem.Model);
  };
  const radiusChange = (e) => {
    setRadius(e.currentTarget.value);
  };

  return (
    <main>
      <Banner Title="Explore Tesla Cars" NoSearch={true} />
      <div className="listing-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="row">
                <div className="col-12">
                  <div className="small-section-tittle2 mb-45">
                    <h4>Advanced Filter</h4>
                  </div>
                </div>
              </div>
              <div className="category-listing mb-50">
                <form
                  className="single-listing"
                  onSubmit={(e) => {
                    e.preventDefault();
                    SearchCar();
                  }}
                >
                  {/* <div className="select-job-items2">
                    <select name="select2">
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </select>
                  </div> */}

                  <div className="select-job-items2 select-form2 mb-3">
                    <select
                      id="makeselections"
                      onChange={makeChange}
                      disabled={true}
                    >
                      <option value="">Select Make</option>

                      {props.global.make.map((item) => {
                        return (
                          <option key={item.Unique_Id} value={item.Model}>
                            {item.Make}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="select-job-items2 select-form2  mb-3">
                    <select id="selectmodal" onChange={modalChange} required>
                      <option value="">Select Model</option>

                      {[...new Set(Modals.map((x) => x.Model))].map((item) => {
                        return (
                          <option
                            key={item.Make_Id + item.Unique_Id}
                            value={item}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="input-form">
                    <input
                      type="number"
                      id="zipcode"
                      placeholder="Zip code"
                      style={{
                        height: "60px",
                        width: "100%",
                        color: "#363636",
                        fontSize: "17px",
                        fontWeight: "400",
                        padding: "9px 33px 9px 24px",
                        position: "relative",
                        borderRadius: "6px",
                      }}
                      required
                    />
                  </div>
                  <div className="select-job-items2 select-form2  mb-3">
                    <div className="small-section-tittle2 mb-20">
                      <h4>Search Radius</h4>
                    </div>
                    <select onChange={radiusChange} defaultValue={radius}>
                      <option value="">Select Radius</option>

                      <option value="25">25 mi</option>
                      <option value="50">50 mi</option>
                      <option value="75">75 mi</option>
                      <option value="100">100 mi</option>
                      <option value="150">150 mi</option>
                      <option value="200">200 mi</option>
                      <option value="500">500 mi</option>
                      <option value="1500">Nationwide</option>
                    </select>
                  </div>

                  <div className="select-Categories  pb-30">
                    <div className="small-section-tittle2 mb-20">
                      <h4>Price range</h4>
                    </div>
                    <Range
                      step={2}
                      defaultValue={[1000, 150000]}
                      min={1000}
                      max={150000}
                      count={3}
                      onChange={onSliderChange}
                      trackStyle={[
                        { backgroundColor: "red" },
                        { backgroundColor: "green" },
                      ]}
                      railStyle={{ backgroundColor: "black" }}
                    />
                    <h4>{`$${rangeStart.toLocaleString()} - $${rangeEnd.toLocaleString()}`}</h4>
                  </div>
                  <div className="select-Categories  pb-30">
                    <label className="container">
                      Certified Cars
                      <input type="checkbox" onChange={CertifiedCheck} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="search-form ">
                    <button type="submit" className="btn">
                      <i className="ti-search"></i> Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-8">
              <div className="row">
                <div className="col-lg-12">
                  <div className="count mb-35">
                    <span>
                      {data
                        ? data.filter(
                            (x) =>
                              x.Price > 0 &&
                              x.Price > rangeStart &&
                              x.Price < rangeEnd
                          ).length
                        : 0}{" "}
                      Listings are available
                    </span>
                  </div>
                </div>
              </div>
              <div className="popular-directorya-area fix">
                <div className="row">
                  {data &&
                    data
                      .filter(
                        (x) =>
                          x.Price > 0 &&
                          x.Price > rangeStart &&
                          x.Price < rangeEnd
                      )
                      .map((item, index) => {
                        return (
                          <div
                            className="col-lg-4  extraDiv"
                            key={index + index}
                          >
                            <Cardview
                              item={item}
                              key=""
                              history={props.history}
                            />
                          </div>
                        );
                      })}
                </div>
              </div>

              {/* <div className="pagination-area text-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="single-wrap d-flex justify-content-center">
                        <nav aria-label="Page navigation example">
                          <ul
                            className="pagination justify-content-start "
                            id="myDIV"
                          >
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <span className="ti-angle-left"></span>
                              </a>
                            </li>
                            <li className="page-item active">
                              <a className="page-link" href="#">
                                01
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                02
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                03
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <span className="ti-angle-right"></span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withGlobalContext(Listing);
