import React, { useState, useEffect } from "react";
import Banner from "../Components/BannerTop";
import Cardview from "../Components/Cardview";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axiosWithAuth from "../utils/Services";
import { withGlobalContext } from "../utils/GlobalContext";

function Contact(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [zipCode, setZipCode] = useState("");

  const contactApi = () => {
    props.global.setLoader(true);

    axiosWithAuth()
      .post("/latest/api/registration/cust/email", {
        name: name,
        email: email,
        contact: contact,
        zipCode: zipCode,
        subject: subject,
        message: message,
      })
      .then((res) => {
        alert("Message Sent.");
        props.global.setLoader(false);
      })
      .catch((res) => {
        props.global.setLoader(false);
      });
  };
  return (
    <main>
      <Banner Title="Contact" NoSearch={true} />
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Get in Touch</h2>
            </div>
            <div className="col-lg-8">
              <form
                className="form-contact contact_form"
                id="contactForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  contactApi();
                }}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="name"
                        id="name"
                        onChange={(e) => {
                          setName(e.currentTarget.value);
                        }}
                        type="text"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter your name'"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        id="email"
                        onChange={(e) => {
                          setEmail(e.currentTarget.value);
                        }}
                        type="email"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter email address'"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        onChange={(e) => {
                          setSubject(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter Subject'"
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        onChange={(e) => {
                          setMessage(e.currentTarget.value);
                        }}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter Message'"
                        placeholder=" Enter Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <button type="submit" className="btn ">
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-3 offset-lg-1">
              {/* <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-home"></i>
                </span>
                <div className="media-body">
                  <h3> Plano.</h3>
                  <p>Dallas, USA 91770</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-tablet"></i>
                </span>
                <div className="media-body">
                  <h3>+1 253 565 2365</h3>
                  <p>Mon to Fri 9am to 6pm</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-email"></i>
                </span>
                <div className="media-body">
                  <h3>support@teslanavigator.com</h3>
                  <p>Send us your query anytime!</p>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default withGlobalContext(Contact);
