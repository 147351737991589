import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginPopup from "./Pages/LoginPopup";
import ProfilePopup from "./Pages/ProfilePopup";
import { withGlobalContext } from "./utils/GlobalContext";
import { Auth } from "aws-amplify";

const Header = (props) => {
  const [ProfilePopupShow, setProfilePopupShow] = useState(false);

  return (
    <>
      {/* <div id="preloader-active">
<div className="preloader d-flex align-items-center justify-content-center">
  <div className="preloader-inner position-relative">
    <div className="preloader-circle"></div>
    <div className="preloader-img pere-text">
      <img src="assets/img/logo/loder.png" alt="" />
    </div>
  </div>
</div>
</div> */}
      <header>
        <div className="header-area header-transparent">
          <div className="main-header header-sticky">
            <div className="container-fluid">
              <div className="menu-wrapper d-flex align-items-center justify-content-between">
                <div className="logo MainLogo">
                  <Link to="/">
                    <img src="assets/img/logo/logo.png" alt="" />
                  </Link>
                </div>
                <div className="main-menu f-right d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li>
                        <a href="/listings">Buy Tesla</a>
                      </li>

                      <li>
                        <a href="/whytesla">Why Tesla?</a>
                      </li>

                      <li>
                        <a href="/social">Social</a>
                        <ul className="submenu">
                          <li>
                            <a href="/social?youtube">Tesla on Youtube</a>
                          </li>
                          <li>
                            <a href="/social?twitter">Tesla on Twitter</a>
                          </li>
                          <li>
                            <a href="/social?fb">Tesla on Facebook</a>
                          </li>
                          <li>
                            <a href="/social?podcast">Tesla Podcasts</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="/dealer">Dealers</a>
                      </li>
                      <li className="mobileSellCarBtn">
                        <Link to="/sellcar" className="btn">
                          Sell Your Car
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="main-menu header-btns d-none d-lg-block f-right">
                  {props.global.user ? (
                    <nav>
                      <ul id="navigations">
                        <li>
                          <Link to="#" className="mr-40">
                            <i className="ti-user"></i>{" "}
                            {props.global.user.username}
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  setProfilePopupShow(true);
                                }}
                              >
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="mylistings">My Car listings</Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={async () => {
                                  props.global.setLoader(true);
                                  await Auth.signOut({ global: true });
                                  props.global.createUserSession(null);

                                  props.global.setLoader(false);
                                }}
                              >
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  ) : (
                    <Link
                      to="#"
                      onClick={() => {
                        props.setLoginPopupShow(true);
                      }}
                      // onClick={() => setRenderAuth(true)}
                      className="mr-40"
                    >
                      <i className="ti-user"></i> Log in
                    </Link>
                  )}
                </div>
                <Link to="/sellcar" className="btn SellBtnLrg">
                  Sell Your Car
                </Link>
                <div className="col-12">
                  <div className="mobile_menu d-block d-lg-none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginPopup
          show={props.LoginPopupShow}
          onHide={() => {
            props.setLoginPopupShow(false);
          }}
        />
        <ProfilePopup
          show={ProfilePopupShow}
          onHide={() => {
            setProfilePopupShow(false);
          }}
        />
      </header>
    </>
  );
};

export default withGlobalContext(Header);
