//prod
let awsConfig = {
  identityPoolId: "us-east-1:9fc0ecc6-ee81-4955-9852-fc2e038f710f",
  region: "us-east-1",
  userPoolId: "us-east-1_FP0ex3J9e",
  userPoolWebClientId: "b9rq944igtu6ca12utip35j8f",
};

//tesing
// let awsConfig = {
//   identityPoolId: "us-east-1:ad5d6cdb-cafe-49f1-9d16-5298c460672ca",
//   region: "us-east-1",
//   userPoolId: "us-east-1_Ni0pbvIKh",
//   userPoolWebClientId: "1sag8s3vrs940d26qqeou3g15g",
// };

export default awsConfig;
