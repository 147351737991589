import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { withGlobalContext } from "../utils/GlobalContext";

function ProfilePopup(props) {
  let userdetails = props.global.user;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      //show={true}
    >
      <Modal.Header closeButton>
        <b>
          <h2 className="contact-title mb-0">User Details</h2>
        </b>
      </Modal.Header>
      <Modal.Body>
        <div className="py-3 px-5">
          <div className="d-flex justify-content-between ">
            <p className="Spctitle">Name</p>
            <p>{userdetails && userdetails.attributes.name}</p>
          </div>
          <div className="d-flex justify-content-between ">
            <p className="Spctitle">Email</p>
            <p>{userdetails && userdetails.attributes.email}</p>
          </div>
          <div className="d-flex justify-content-between ">
            <p className="Spctitle">Username</p>
            <p>{userdetails && userdetails.username}</p>
          </div>
          <div className="d-flex justify-content-between ">
            <p className="Spctitle">Phone no.</p>
            <p> {userdetails && userdetails.attributes.phone_number}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <button
          type="submit"
          className="btn float-right"
          style={{ padding: "14px" }}
        >
          <i className="ti-close"></i> Close
        </button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default withGlobalContext(ProfilePopup);
